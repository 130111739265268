<script lang="ts">
// menu/navigation sidebar
import { useConfigStore } from '~/store'

export default {
    name: 'NavigationSidebar',
    props: {
        rail: { type: Boolean, default: false },
        noLogo: { type: Boolean, default: false },
    },
    setup() {
        const configStore = useConfigStore()
        return {
            configStore,
        }
    },
    data() {
        return {
            hover: false,
        }
    },
    computed: {
        isLoggedIn() {
            return this.$currentUser().k
        },
    },
    methods: {},
}
</script>

<template>
    <div
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <!-- <v-list-item prepend-avatar="https://deci.sdis84.fr/images/DECI/horizontal-couleur.jpg" -->
        <template v-if="!noLogo">
            <v-list-item nav>
                <div class="w-100 text-center">
                    <img
                        src="@nuxtcommon/assets/images/logo.png"
                        alt="Pildom"
                        :width="!rail || hover ? 150 : 40"
                    >
                </div>
            </v-list-item>

            <v-divider />
        </template>

        <v-list
            id="main-menu"
            :class="noLogo && 'mt-3'"
            nav
        >
            <NuxtLink to="/">
                <v-list-item
                    prepend-icon="mdi-home"
                >
                    <v-list-item-title>Accueil</v-list-item-title>
                </v-list-item>
            </NuxtLink>

            <!-- Configuration -->
            <v-list-subheader
                v-if="$permissionCheck('menu_config')"
            >
                Configuration
            </v-list-subheader>
            <NuxtLink
                v-if="$permissionCheck('page_page')"
                to="/pages"
            >
                <v-list-item
                    prepend-icon="mdi-note-text"
                    title="Pages"
                />
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('application_page')"
                to="/applications"
            >
                <v-list-item
                    prepend-icon="mdi-puzzle"
                    title="Applications"
                />
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('mapService_page')"
                to="/services-cartographiques"
            >
                <v-list-item
                    prepend-icon="mdi-sitemap"
                    title="Services cartographiques"
                />
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('mapLayer_page')"
                to="/couches-cartographiques"
            >
                <v-list-item
                    prepend-icon="mdi-layers"
                    title="Couches cartographiques"
                />
            </NuxtLink>

            <!-- Administration -->
            <v-list-subheader
                v-if="$permissionCheck('menu_admin')"
                class="pb-2"
            >
                Administration
            </v-list-subheader>
            <NuxtLink
                v-if="$permissionCheck('accessRequest_page')"
                to="/demandes-acces"
            >
                <v-list-item
                    class="mb-0"
                    prepend-icon="mdi-account-lock"
                    title="Demandes d'accès"
                >
                    <template v-if="configStore.currentConfig.access_request_total" #append>
                        <v-badge
                            color="warning"
                            :content="configStore.currentConfig.access_request_total"
                            inline
                        />
                    </template>
                </v-list-item>
            </NuxtLink>

            <NuxtLink
                v-if="$permissionCheck('organization_page')"
                to="/structures"
            >
                <v-list-item
                    prepend-icon="mdi-office-building"
                    title="Structures"
                />
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('user_page')"
                to="/utilisateurs"
            >
                <v-list-item
                    prepend-icon="mdi-account-multiple"
                    title="Utilisateurs"
                />
            </NuxtLink>
        </v-list>
    </div>
</template>
